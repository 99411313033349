import React, { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkSide from "../../utils/useDarkSide";
import { defaultProperties } from "../../utils/constants";

const Switcher = () => {
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toogleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };
  return (
    <div>
      <DarkModeSwitch
        checked={darkSide}
        onChange={toogleDarkMode}
        size={24}
        color="#5B6B79"
        {...defaultProperties}
      />
    </div>
  );
};

export default Switcher;
