import axios from "axios";
import { API_URL } from "./api";
import { removeToken, setToken } from "./tokenStorge";

axios.interceptors.request.use(
  (config) => config,
  (e) => Promise.reject(e)
);
axios.interceptors.response.use(
  (response) => response,
  (e) => {
    if (typeof e?.toJSON === "function") {
      const error = e?.toJSON();

      if (error?.status === 401) {
        removeToken();
        window.location.href = "/login";
      }
      if (error?.message === "Network Error") {
        // toast.warning(
        //   localStorage.getItem("i18nextLng") === "uz"
        //     ? "Internetingiz uzildi!"
        //     : "Нет подключения к Интернету!"
        // );
      }
      if (process.env.NODE_ENV === "development") {
        // console.log(error);
      }
    }
    return Promise.reject(e);
  }
);
const Axios = (Api_Url, access_token) => {
  const isLocal = window.location.href.includes("localhost");
  let token = "";
  if (isLocal) {
    token = access_token
      ? access_token
      : "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMxOTI4NTc1LCJpYXQiOjE3MzEwNjQ1NzUsImp0aSI6IjQ3YzBiMzI5ZjY4MDQ3MDY4YmYwNzMwODU1YWY5ZWJhIiwidXNlcl9pZCI6MiwidXNlcm5hbWUiOiJtaWdyYXRpb24yIiwiZW1haWwiOiIiLCJmdWxsX25hbWUiOiIgIiwicGVybWlzc2lvbnMiOlsidmlld19ib3JkZXJjcm9zcyJdLCJyb2xlcyI6WyJiaW9ncmFwaGljYWxfZGF0YV9vZl90aGVfbWlncmFudCJdfQ.TRjuNGqt1w6QsfULA9MUr38VTD0WM1T45g5kEw0KDQHey4PIqMtsjMPFd9NiI0BzTF9l1rrPy6FxJZoz2S6kAt-D0tO8O2QlHjphjHfmfr-Z1k-d96F4weiZRIYX-gsnJL9TwqVzW34V_Nu_d4VyPj7eMPgw2A4mEge8Nu7oXK8bZG-P8-klISZQO0eYmpRdiHB_afmoA5aWwH1CS1s52Ul67Hr2H87KzTliWgr5yosI19M8bwFx4BHWPCnXxefFE_eoEsV2NmdyE2FAPOodGGZj6q7CVHBL1GZR8pQWj-4ON45BPY8tjiV87n_5qZ4nTeVozL_UfJVSaCsQyupFLQ";
    setToken(token);
  } else {
    token = access_token ? access_token : localStorage.getItem("token");
  }

  const defaultOptions = {
    baseURL: Api_Url ? Api_Url : API_URL,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
          lan: localStorage.getItem("i18nextLng") || "uz",
        }
      : { lan: localStorage.getItem("i18nextLng") || "uz" },
    params: { lan: localStorage.getItem("i18nextLng") || "uz" },
  };

  return {
    get: (url, options = {}) =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (url, data, options = {}) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (url, options = {}) =>
      axios.delete(url, { ...defaultOptions, ...options }),
  };
};
export default Axios;
